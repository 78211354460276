.bg-home {
  background-image: url('../img/BgHome.png');
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

tr:hover {
  background-color: #f5f5f5;
}

.disable {
  pointer-events: none;
  opacity: 0.4;
}

.trans {
  transition: width 2s;
}

.stripe-button {
  display: block;
  position: relative;
  padding: 0 12px;
  height: 30px;
  background: #1275ff;
  background-image: -webkit-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: -moz-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: -ms-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  font-size: 15px;
  line-height: 30px;
  color: white;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 5px;
}

#mapBox div:first-child {
  position: relative !important;
}

@media only screen and (max-width: 768px) {
  .direction {
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    margin-top: 15px
  }

  .custom-margin {
    margin-right: 0 !important;
  }
}

.direction {
  flex-direction: column;
  display: flex !important;
}

.custom-margin {
  margin-right: 15rem;
}
