.font-montserrat-Regular {
  font-family: Montserrat-Regular;
}

.font-montserrat-bold {
  font-family: Montserrat-Bold;
}
.font-montserrat-semibold {
  font-family: Montserrat-SemiBold;
}

.font-montserrat-medium {
  font-family: Montserrat-Medium;
}

.overlay-block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.overlay-block-blue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 128, 0.1);
  overflow: hidden;
}

.full-screen {
  min-width: 100vh;
  min-height: 100vh;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5 !important;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
  color: navy;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='email']:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px gray;
  outline: 0 none;
}

.card-rounded {
  border-radius: 25px;
}

.text-heading {
  color: #fd6163;
}

.btn {
  background: #fd6163;
  border: #fd6163;
  border-radius: 10px;
}

.bg-dark-blue {
  background-color: #4065b4;
}

.bg-light-blue {
  background-color: #5698d4;
}

.modal-close-btn {
  position: absolute;
  right: 0;
  z-index: 1;
}

.container-top {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  min-height: 100vh;
}

.tr-bg-color {
  background-color: #eaf0f4;
}

.gray-bg-color {
  background-color: #f2f2f2;
}

.solid-blue {
  background-color: #334752;
}

.light-mud {
  color: #a3a6b4;
}

.gray-icon {
  color: #bcbccb;
}

a,
a label {
  cursor: pointer;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #334752;
  border-color: #334752;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #334752;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #334752;
  border-color: #334752;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #334752;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
