.border-thin {
  border-width: 0;
}
.time-text {
  font-size: 9px;
}
.card-desc {
  font-size: 11px;
}
.hire-button {
  border-color: 'cus-yellow';
  border-width: 1px;
  font-size: 10px;
  color: white;
  background-color: transparent;
}
