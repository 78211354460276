.selected {
  background: #fd6163;
  border-radius: 12px;
  color: white !important;
}

.top-nav a:hover {
  @extend .selected;
}

@media screen and (max-width: 991px) {
  .top-nav {
    // background: rgba(0, 0, 0, 0.7);
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #2b2828;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
  background-color: #585858;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.open-nav-pannel {
  margin-left: 250px;
}

.close-nav-pannel {
  margin-left: 0;
}

.open-nav {
  width: 250px;
}

.close-nav {
  width: 0;
}
